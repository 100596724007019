'use client';

import { useState, useEffect, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useImage } from 'react-image';

import { cn } from '@/lib/utils';

export type TransformOptions = string[];

export const buildURL = (path: string, transform?: TransformOptions): string => {
  if (/^https?:\/\//.test(path)) {
    return path;
  }

  const url = process.env.NEXT_PUBLIC_IMAGES_API_URL + (path.startsWith('/') ? path : `/${path}`);

  if (transform?.length) {
    return `${url}?twic=v1/${transform.join('/')}`;
  }

  return url;
};

export type ImageProps = Omit<React.ComponentProps<'img'>, 'src'> & {
  src: string;
  transform?: TransformOptions;
};

export default function Image({
  className,
  src,
  transform = ['inside=300x300'],
  ...props
}: ImageProps) {
  const [source, setSource] = useState<string | null>(null);

  useEffect(() => {
    setSource(buildURL(src, transform));
  }, [src, transform]);

  return (
    <div className="w-full h-full">
      <ErrorBoundary
        fallbackRender={() => (
          <div className="w-full h-full bg-slate-200" />
        )}
      >
        <Suspense
          fallback={(
            <img // eslint-disable-line jsx-a11y/alt-text
              src={source + '/output=preview'}
              className={cn('w-full h-full object-contain', className)}
              {...props}
            />
          )}
        >
          {source && (
            <InnerImage
              src={source}
              className={cn('w-full h-full object-contain', className)}
              {...props}
            />
          )}
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}

function InnerImage({ src, ...props }: ImageProps) {
  const { src: source } = useImage({
    srcList: src,
  });

  return (
    <img // eslint-disable-line jsx-a11y/alt-text
      src={source}
      {...props}
    />
  );
}
